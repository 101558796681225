function nivoCaptionIn(property, effect, speed) {
	if (property == 'top')
		jQuery('.nivo-caption').animate({top: '0'}, speed, effect, fadeInProducts);
	else if (property == 'bottom')
		jQuery('.nivo-caption').animate({bottom: '0'}, speed, effect, fadeInProducts);
	else if (property == 'left')
		jQuery('.nivo-caption').animate({left: '0'}, speed, effect, fadeInProducts);
	else if (property == 'right')
		jQuery('.nivo-caption').animate({right: '0'}, speed, effect, fadeInProducts);
	else if (property == 'opacity')
		jQuery('.nivo-caption').animate({opacity: '1'}, speed, 'swing', fadeInProducts);
}
function nivoCaptionOut(property, effect, speed) {
	if (property == 'top')
		jQuery('.nivo-caption').animate({top: '-100%'}, speed, effect);
	else if (property == 'bottom')
		jQuery('.nivo-caption').animate({bottom: '-100%'}, speed, effect);
	else if (property == 'left')
		jQuery('.nivo-caption').animate({left: '-100%'}, speed, effect);
	else if (property == 'right')
		jQuery('.nivo-caption').animate({right: '-100%'}, speed, effect);
	else if (property == 'opacity')
		jQuery('.nivo-caption').animate({opacity: '0'}, speed, 'swing');
}
function fadeInProducts() {
	jQuery('.nivo-caption .js-slide-products-related').fadeIn();
}

$(window).load(function() {
	var sliderObject = $('#aoneSlider'),
		sliderOverlay = $('#js-nivoSliderOverlay'),
		settings = sliderObject.data('settings');

	if (sliderObject.length) {
		sliderObject.nivoSlider({
			effect: settings.effect,
			slices: Number(settings.slices),
			boxCols: Number(settings.boxCols),
			boxRows: Number(settings.boxRows),
			animSpeed: Number(settings.animSpeed),
			pauseTime: Number(settings.pauseTime),
			startSlide: Number(settings.startSlide),
			directionNav: settings.directionNav,
			controlNav: settings.controlNav,
			controlNavThumbs: settings.controlNavThumbs,
			pauseOnHover: settings.pauseOnHover,
			manualAdvance: settings.manualAdvance,
			randomStart: settings.randomStart,
			afterLoad: function(){
				nivoCaptionIn(settings.caption_effect, 'easeInBack', 400);
				sliderOverlay.hide();
				sliderObject.fadeIn(400);
			},
			beforeChange: function(){
				nivoCaptionOut(settings.caption_effect, 'easeInBack', 400);
			},
			afterChange: function(){
				nivoCaptionIn(settings.caption_effect, 'easeInBack', 400);
			}
		});
	}

    //Slider bottom
    var sliderObject2 = $('#aoneSlider2'),
        sliderOverlay2 = $('#js-nivoSliderOverlay2'),
        settings2 = sliderObject2.data('settings');

    if (sliderObject2.length) {
        sliderObject2.nivoSlider({
            effect: settings2.effect,
            slices: Number(settings2.slices),
            boxCols: Number(settings2.boxCols),
            boxRows: Number(settings2.boxRows),
            animSpeed: Number(settings2.animSpeed),
            pauseTime: Number(settings2.pauseTime),
            startSlide: Number(settings2.startSlide),
            directionNav: settings2.directionNav,
            controlNav: settings2.controlNav,
            controlNavThumbs: settings2.controlNavThumbs,
            pauseOnHover: settings2.pauseOnHover,
            manualAdvance: settings2.manualAdvance,
            randomStart: settings2.randomStart,
            afterLoad: function(){
                nivoCaptionIn(settings2.caption_effect, 'easeInBack', 400);
                sliderOverlay2.hide();
                sliderObject2.fadeIn(400);
            },
            beforeChange: function(){
                nivoCaptionOut(settings2.caption_effect, 'easeInBack', 400);
            },
            afterChange: function(){
                nivoCaptionIn(settings2.caption_effect, 'easeInBack', 400);
            }
        });
    }
});